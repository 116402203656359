import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/index')
  },
  {
    path: '/password',
    name: 'password',
    component: () => import('@/views/password/index')
  },
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () => import('@/views/ueseagreement/privacypolicy')
  },
  {
    path: '/useragreement',
    name: 'useragreement',
    component: () => import('@/views/ueseagreement/useragreement')
  },
  {
    path: '/wxlogin',
    name: 'wxlogin',
    component: () => import('@/views/login/wxlogin')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
